import request from './request'

export function query(data) {
  return request({
    url: '/article/queryV2',
   // url: '/article/aaa.json',
    method: 'post',
    data
  })
}

export function detail(data) {
  return request({
    url: '/article/detail',
    method: 'post',
    data
  })
}

